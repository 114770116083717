* {
    padding: 0%;
    margin: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Bold.eot');
    src: url('../fonts/Rajdhani-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-Bold.woff2') format('woff2'),
        url('../fonts/Rajdhani-Bold.woff') format('woff'),
        url('../fonts/Rajdhani-Bold.ttf') format('truetype'),
        url('../fonts/Rajdhani-Bold.svg#Rajdhani-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Light.eot');
    src: url('../fonts/Rajdhani-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-Light.woff2') format('woff2'),
        url('../fonts/Rajdhani-Light.woff') format('woff'),
        url('../fonts/Rajdhani-Light.ttf') format('truetype'),
        url('../fonts/Rajdhani-Light.svg#Rajdhani-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Medium.eot');
    src: url('../fonts/Rajdhani-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-Medium.woff2') format('woff2'),
        url('../fonts/Rajdhani-Medium.woff') format('woff'),
        url('../fonts/Rajdhani-Medium.ttf') format('truetype'),
        url('../fonts/Rajdhani-Medium.svg#Rajdhani-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-Regular.eot');
    src: url('../fonts/Rajdhani-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-Regular.woff2') format('woff2'),
        url('../fonts/Rajdhani-Regular.woff') format('woff'),
        url('../fonts/Rajdhani-Regular.ttf') format('truetype'),
        url('../fonts/Rajdhani-Regular.svg#Rajdhani-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rajdhani';
    src: url('../fonts/Rajdhani-SemiBold.eot');
    src: url('../fonts/Rajdhani-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Rajdhani-SemiBold.woff2') format('woff2'),
        url('../fonts/Rajdhani-SemiBold.woff') format('woff'),
        url('../fonts/Rajdhani-SemiBold.ttf') format('truetype'),
        url('../fonts/Rajdhani-SemiBold.svg#Rajdhani-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: 'Rajdhani'
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ol,
ul,
li,
a {
    margin: 0px;
    padding: 0px;
    line-height: normal;
    font-family: 'Rajdhani';
    list-style-type: none;
    text-decoration: none !important;

}

ul li {
    margin: 0;
    padding: 0;
    line-height: normal;
}

/* header start  */
header {
    width: 100%;
    height: 100%;
}

.navbar-toggler {
    padding: 10px;
    font-size: 1.25rem;
    line-height: 1;
    background-color: #18f6c8;
    border: 1px solid #000;
    border-radius: .25rem;
    transition: box-shadow .15s ease-in-out;
}

.top-menu {
    padding: 20px 0;
    background-color: #262c3e;
    position: relative;
    z-index: 2;
}

.top-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top-menu::after {
    content: '';
    background-color: #18f6c8;
    height: 100%;
    right: 0;
    position: absolute;
    width: 32%;
    z-index: 1;
    top: 0;

}

.top-text ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;

}

.top-text ul li a {
    color: #18f6c8;
    font-size: 17px;
    font-weight: 600;
    padding-left: 15px;
}

.top-text ul li:hover a,
.top-text ul li:focus a {
    color: #fff;
    transition: all .3s ease;
}

.top-text ul li a i {
    margin-right: 10px;
    font-size: 20px;
}

.top-icon ul {
    display: flex;
    gap: 0 10px;
    align-items: center;
    margin-bottom: 0;
}

.top-icon {
    position: relative;
    z-index: 2;
    width: 20%;

}

.top-icon ul li a {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    background-color: #1a2135;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-icon ul li a i {
    color: #18f6c8;
}

.top-icon ul li a:hover,
.top-icon ul li a:focus {
    background-color: #fff;
    transition: all .3s ease;
}

.top-icon ul li a:hover i,
.top-icon ul li a:focus i {
    color: #000;
}

a.closebtn {
    display: none;
}

div#mySidenav {
    display: flex;
}

.navbar {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

ul.navbar-nav.ms-auto {
    margin-right: 30px;
}

li.nav-item {
    align-content: center;
    margin: 0 15px;
}

.navbar-nav {
    align-items: center;
}

.navbar-nav .nav-link {
    font-size: 20px;
    color: #1a2135;
    font-weight: 500;
    margin: 0 10px;
    position: relative;
}

.navbar-nav .nav-link::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    margin: 0 auto;
    background-color: #000;
}

.navbar-nav .nav-link:hover:after,
.navbar-nav .nav-link:active:after,
.navbar-nav .nav-link:focus:after {
    width: 100%;
    transition: all .3s ease;
}

.nav-link li {
    margin: 0 15px;
}

.custome-dropdown a {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}

select.custome-dropdown {
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px;
    border: 1px solid #00f9cb !important;
    height: 50px;
    padding: 0 40px;
    color: #000;
    font-family: 'Rajdhani';
    position: relative;
}

select.custome-dropdown option {
    color: #000;
    border: 1px solid #00f9cb !important;
    font-family: 'Rajdhani';
    font-size: 20px;
    font-weight: 600;
    border-radius: 50px;
    border: 1px solid #00f9cb !important;
}

.custome-dropdown::before {
    content: "\F658";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    font-family: "Bootstrap Icon";
    color: #00f9cb;
    font-weight: 600;
}

.nav-right {
    display: flex;
    align-items: center
}


/*============ banner start  =====*/
.banner {
    height: calc(100vh - 180px);
    width: 100%;
    background-image: url(../images/banner-back.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: center;
}

.overly {
    background-color: #1a2135;
    max-width: 100%;
    position: relative;
    opacity: 0.9;
    width: 100%;
    height: 100%;
}

.banner-text {
    color: #fff;
    position: absolute;
    transform: translateY(-50%);
    top: 40%;
    left: 0;
    right: 0;
    text-align: center;
    width: 60%;
    margin: auto auto;

}

.banner-text h1 {
    font-size: 74px;
    color: #4ef3d2;
    font-weight: 700;
}

.banner-text span b {
    color: #fff;
}

.banner-text span {
    color: #fff;
    font-weight: 500;
    display: block;
}

.banner-text h4 {
    font-size: 68px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 20px;
}

.banner-text p {
    font-size: 20px;
}

.scroll-btn {
    height: 190px;
    max-width: 80%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -9px;
    background-image: url('../images//circle.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: auto;
    padding: 20px;
}

.go-down-btn {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    transition: all .3s ease;
}

.go-down-btn p {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.scroll-icon {
    height: 100px;
    width: 100px;
    background-color: transparent;
    border: 1px solid #18f6c8;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 3px;
    position: absolute;
    top: 30px;
}

.shape-one {
    position: relative;
    width: 210px;
    top: -23px;
    animation: spin 10s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.scroll-icon i {
    font-size: 30px;
    transform: scaleY(-1);
    color: #00f6d4;
    font-weight: 400;
}

@keyframes rotate-btn {
    to {
        transform: rotate(360deg);
    }
}

.go-down-btn text {
    animation: rotate-btn 10s linear infinite;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

/* banner end */
.language-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-btn {
    background-color: white;
    border: 2px solid #262c3e;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    border-radius: 30px;
    font-weight: 600;
    color: #262c3e !important;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #262c3e;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 210px;
    text-align: left;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
}

.dropdown-btn i {
    color: #4ef3d2;
}

.language-dropdown .dropdown-content li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    font-weight: 600;
}

.dropdown-content li:last-child {
    border-bottom: 0;
}

.dropdown-content li:hover {
    background-color: #f0f0f0;
}

/* .language-dropdown:hover .dropdown-content {
    display: block;
  } */
/* ========sevtiom start img======= */
.about {
    padding: 120px 0;
}

h5.about-title {
    font-size: 36px;
    color: #18f6c8;
    font-weight: 600;
    margin-bottom: 10px;
}

h3.about-data {
    font-size: 36px;
    color: #1a2135;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 35px;
}

.about-left .about-text {
    font-size: 20px;
    color: #000000;
    font-weight: 700;
    width: 91%;
    margin-bottom: 10px;
    line-height: 23px;
}

.about-left .about-peregraph {

    font-size: 16px;
    color: #4b4b4b;
    font-weight: 500;
    margin-bottom: 10px;
    line-height: 21px;
}

.about-btn {
    width: 175px;
    height: 55px;
    border-radius: 50px;
    background-color: #fff;
    border: 2px solid #000;
    margin-top: 20px;
}

.about-btn:hover,
.about-btn:focus {
    background-color: #000;
    transition: all .3s ease-in-out;
}

.about-btn a {
    font-size: 20px;
    text-transform: uppercase;
    color: #1a2135;
    line-height: 55px;
    display: flex;
    justify-content: center;
    font-weight: 700;
}

.about-btn:hover a,
.about-btn:focus a {
    color: #00f9cb;
}

.about-img {
    width: 100%;
    height: 100%;
    position: relative;
}

.about-img::after {
    background-color: #1a2135;
    opacity: 0.7;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    content: '';
    right: 0;
    border-radius: 30px;
}

.about-img img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    object-fit: cover;
    object-position: left;
}

/* project counter   */
.about-counter {
    padding-top: 90px;
}

.project-count {
    background-color: #acffee;
    width: 100%;
    height: 180px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.project-count h3 {
    font-size: 50px;
    color: #1a2135;
    font-weight: 700;
    line-height: 40px;
}

.project-count h4 {
    font-size: 30px;
    color: #1a2135;
    font-weight: 600;
}

/* =======sevtion end img ======= */

/* img card start   */
.img-card {
    padding: 100px 0;
    background-color: #1a2135;
}

.imgcard-title {
    text-align: center;
    margin-bottom: 50px;
}

.imgcard-title h4 {
    font-size: 24px;
    color: #18f6c8;
    font-weight: 600;
    line-height: 6px;
    line-height: 22px;
    margin-bottom: 10px;
}

.imgcard-title h5 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 700;
    line-height: 32px;
}

.card-info {
    background-color: #ececec;
    border-radius: 20px;
    margin-bottom: 25px;
}

.card-img {
    width: 100%;
}

.card-img img {
    width: 100%;
    border-radius: 20px 20px 0 0;
    height: 100%;
    object-fit: cover;
}

.card-body {
    padding: 30px 30px 10px;
    min-height: 300px;
    text-align: center;
}

.body-img {
    width: 70px;
    height: 55px;
    background-color: #1a2135;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 auto 30px;
}

h5.img-text {
    font-size: 20px;
    color: #000;
    font-weight: 700;
    margin-bottom: 10px;
}

p.img-title {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin-bottom: 25px;
}

.body-btn {
    display: flex;
    align-items: center;
}

.body-btn a {
    font-size: 18px;
    color: #1a2135;
    font-weight: 700;
}

.body-btn i {
    color: #18f6c8;
    font-size: 30px;
    padding-left: 10px
}

.main-card-btn {
    text-align: center;
    width: 175px;
    height: 55px;
    background-color: #18f6c8;
    border: 1px solid #18f6c8;
    border-radius: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 40px;
}

.main-card-btn:hover,
.main-card-btn:focus {
    background-color: transparent;
    transition: all .3s ease-in-out;
}

.main-card-btn a {
    font-size: 20px;
    color: #1a2135;
    font-weight: 700;
    text-transform: uppercase;
}

.main-card-btn:hover a,
.main-card-btn:focus a {
    color: #18f6c8;
    transition: all .3s ease-in-out;
}

/* img card end   */

/*   why choose section start  */
.why-choose {
    padding: 150px 0;
}

.service-data h5 {
    font-size: 24px;
    color: #18f6c8;
    font-weight: 600;
}

.service-data h3 {
    font-size: 36px;
    color: #1a2135;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 35px;
}

.service-data h6 {
    font-size: 15px;
    color: #000000;
    font-weight: 400;
    margin-bottom: 23px;
    line-height: 19px;
    ;
}

.service-datalist ul {
    padding-left: 0;
}

.service-datalist ul li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.list-img {
    width: 50px;
    height: 50px;
    background-color: #1a2135;
    border-radius: 50px;
    border: 2px solid #18f6ca;
    display: flex;
    align-items: center;
    justify-content: center;
}

.list-text {
    margin-left: 8px;
    width: 60%;
}

.list-text h4 {
    font-size: 20px;
    color: #1a2135;
    font-weight: 700;
}

.list-text p {
    font-size: 16px;
    color: #1a2135;
    font-weight: 500;
}

.service-img {
    height: 100%;
    position: relative;
}

.service-img img {
    width: 400px;
    height: 100%;
    object-fit: cover;
    border-radius: 20px
}

.service-img::after {
    content: '';
    background-color: #18f6c8;
    opacity: 0.6;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    width: 400px;
    border-radius: 20px
}

.service-img-postion {
    position: absolute;
    bottom: 29px;
    z-index: 3;
}

/* why choose section  end */
/*   slivk slider start  */
.slider {
    padding: 100px 0;
    background-color: #18f6c8;
    position: relative;
}

.slider::before {
    content: '';
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../assets/images/about.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    position: absolute;
    left: 0;
    height: 100%;
    width: 25%;
    left: auto;
    bottom: 0;
    z-index: 0;
}

.slider-text {
    margin-bottom: 50px;
    text-align: center;
}

.slider-text h4 {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
}

.slider-text h5 {
    font-size: 37px;
    color: #00f9cb;
    font-weight: 700;
}

.slider-content .slick-arrow.slick-next {
    position: absolute;
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
    z-index: 1 !important;
    color: #000 !important;
}

.slider-content .slick-arrow.slick-prev {
    position: absolute;
    left: auto;
    right: 50px;
    top: auto;
    bottom: 0px;
    z-index: 1;
    color: #000;
}

.slider-content .slick-prev:before,
.slick-next:before {
    color: #000 !important;
}

.slider-content .slick-next:before {
    content: '\F285';
    font-family: "bootstrap-icons";
}

.slider-content .slick-prev:before {
    content: '\F284';
    font-family: "bootstrap-icons";
}


.slider-content .slick-slide img {
    width: 100%;
    padding-right: 12px;
}

.slider-content .slick-arrow {
    height: 40px;
    width: 40px;
    background-color: #fff;
}

.slider-content .slick-initialized .slick-slide {
    display: block;
    position: relative;
}

.img-tite {
    position: absolute;
    background-color: #d1d3d7;
    top: auto;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 80%;
    margin: auto;
    text-align: center;
    padding: 15px 0;
    border-radius: 15px;
}

.img-tite h5 {
    font-size: 20px;
    color: #1a2135;
    font-weight: 700;
    margin-bottom: 3px;
}

.img-tite p {
    font-size: 16px;
    color: #1a2135;
    font-weight: 500;
    margin-bottom: 0;
}

/* slick sli8der end  */
/* partner start  */
.partner {
    padding: 100px 0;
    background-color: #ffffff;
}

.partner-back {

    background-color: #1a2135;
    padding: 30px;

}

.dummy-img {
   
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    
}
.dummy-img img{width: 100%;}

/* partner end  */


/* footer start  */
.footer {
    padding-top: 100px;
    background-color: #1a2135;
}

.footer-info img {
    margin-bottom: 20px;
}

.footer-info p {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    width: 75%;
    margin-bottom: 30px;
}

.footer-icon ul {
    display: flex;
    padding-left: 0;
    gap: 15px;
}

.footer-icon ul li a {
    color: #1a2135;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    background-color: #4ef3d2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-icon ul li a i {
    font-size: 25px;
}

.footer-icon ul li a:hover,
.footer-icon ul li a:focus,
.footer-icon ul li a:active {
    background-color: #fff;
    transition: all .3s ease;
}

.footer-link ul {
    padding-left: 0;
}

.footer-link h4 {
    margin-bottom: 30px;
    color: #fff;
    position: relative;
    font-size: 26px;
    font-weight: 700;
}

.footer-link h4::after {
    position: absolute;
    border: 2px solid #18f6c8;
    content: '';
    bottom: -6px;
    width: 73px;
    left: 0;

}

.footer-link ul li a {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}
.section-link{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.footer-link i {
    color: #18f6c8;
}

.footer-link h3 {
    font-size: 18px;
    color: #fff;
    padding-left: 10px;
}

.footer-contect ul {
    padding-left: 0;
}

.footer-contect li {
    margin-bottom: 10px;
}

.footer-contect ul li h3 img {
    width: 25px;
    margin-right: 8px;
}

.footer-contect h4 {
    margin-bottom: 30px;
    color: #fff;
    position: relative;
    font-size: 26px;
    font-weight: 700;
}

.footer-contect h4::after {
    position: absolute;
    border: 2px solid #18f6c8;
    content: '';
    bottom: -6px;
    width: 73px;
    left: 0;

}

.footer-contect ul li a {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.footer-contect i {
    color: #18f6c8;
}

.footer-contect h3 {
    font-size: 18px;
    font-weight: 600;
    color: #00f6d4;
    margin-bottom: 10px;
}

.footer-bottom {
    border-bottom: 2px solid #4ef3d2;
    padding: 50px 0;
}

.copy-right {
    text-align: center;
    padding: 25px 0;
}

.copy-right h6 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.footer-address a {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px !important;
}

.footer-contact li {
    display: inline-block;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #fff;
}

.footer-contact li:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 0px;
}

.footer-contact li a {
    margin-bottom: 0 !important;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

/* fooer end  */





/* ==================Contect page   ================= */
/* banner start   */
.contect-banner {
    height: 65vh;
    width: 100%;
    background-image: url(../images/banner-back.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: top;
}

.contect-overly {
    background-color: #1a2135;
    max-width: 100%;
    position: relative;
    opacity: 0.9;
    width: 100%;
    height: 65vh;
}

.contact-text {
    color: #fff;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.contact-text h1 {
    font-size: 74px;
    color: #4ef3d2;
    font-weight: 700;
}

.contact-text b {
    color: #fff;
}





/* banner end   */


/* adress tect start  */
.address {
    padding: 100px 0;
}

.address .address-card {
    background-color: #acffee;
    width: 100%;
    min-height: 300px;
    border-radius: 15px;
    padding: 30px 20px;
}

.address-card img {
    margin-bottom: 20px;
}

.address-card h2 {
    font-size: 36px;
    color: #1a2135;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 10px
}

.address-card a {
    font-size: 20px;
    color: #1a2135;
    font-weight: 600;
    line-height: 22px;
}

/*  adress tect end  */

/* form start  */
.form-info {
    padding-bottom: 100px;
}

.form-text {
    width: 100%;
    margin: auto;
    text-align: center;
    max-width: 52%;
    margin-bottom: 20px;
}

.form-text h3 {
    font-size: 24px;
    color: #18f6c8;
    font-weight: 600;
}

.form-text h4 {
    font-size: 40px;
    color: #1a2135;
    font-weight: 700;
}

.form-data {
    background-color: #1a2135;
    height: 100%;
    width: 100%;
    padding: 40px 30px;
    border-radius: 15px;
}

input.form-control {
    height: 55px;
    margin-bottom: 30px;
    border-radius: 0;
    font-size: 20px;
    color: #1a2135;
    font-weight: 600;
}

textarea#exampleFormControlTextarea1 {
    resize: none;
    height: 150px;
    margin-bottom: 30px;
    font-size: 20px;
    color: #1a2135;
    font-weight: 600;
    border-radius: 0;
}

.form-data button {
    width: 100%;
    border: none;
    height: 50px;
    background-color: #18f6c8;
    border: 1px solid #18f6c8;
}

.form-data button:hover,
.form-data button:focus {
    background-color: transparent;
    transition: all .3s ease;
}

.form-data button a {
    font-size: 24px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
}

.form-right {
    width: 100%;
    position: relative;
}

.form-img {
    width: 100%;
    height: 480px;
    object-fit: cover;
    object-position: left;
    border-radius: 15px;

}

.form-right::after {
    content: '';
    background-color: #18f6c8;
    opacity: 0.8;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    border-radius: 15px;
}

.right-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 20px;
    z-index: 1;
}

.technical-img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.right-text h5 {
    font-size: 28px;
    color: #1a2135;
    font-weight: 700
}

.right-text p {
    font-size: 18px;
    color: #1a2135;
    font-weight: 500;
    margin-bottom: 20px;
}

.form-btn {
    background-color: #1a2135;
    border-radius: 6px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-btn a {
    font-size: 20px;
    color: #18f6c8;
    font-weight: 700;
}

.form-btn a:hover {
    color: #fff;
}

/*  form end */



/* ============== product page start ================== */

/* banner start  */
.product-banner {
    background-image: url(../images/banner-back.png);
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: top;
    height: 70vh;
    width: 100%;
}

.product-overly {
    background-color: #1a2135;
    max-width: 100%;
    position: relative;
    opacity: 0.9;
    width: 100%;
    height: 70vh;
}

.product-text {
    color: #fff;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.product-text h1 {
    font-size: 74px;
    color: #4ef3d2;
    font-weight: 700;
}

.product-text b {
    color: #fff;
}

/*  banner end */
/*   product card  start  */

.product-card {
    padding: 100px 0;
}

.product-info {
    margin-bottom: 24px;
    overflow: hidden;
}

.product-img {
    width: 100%;
    overflow: hidden;
}

.product-img img {
    width: 100%;
    min-height: 250px;
    object-fit: cover;
    transition: 6s cubic-bezier(0.24, 0.74, 0.58, 1);
    transform: scale(1);
}

.product-info:hover .product-img img,
.product-info:focus .product-img img {
    transition: 6s cubic-bezier(0.24, 0.74, 0.58, 1);
    transform: scale(1.3);
}

.product-body {
    padding: 30px 25px;
    background-color: #edfffb;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    min-height: 250px;
}

.product-body-img {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.body-left {
    width: 70px;
    height: 55px;
    background-color: #1a2135;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.body-right img {
    width: 80px;
    height: 70px;
    opacity: 0.2;
}

.product-body h5 {
    font-size: 20px;
    color: #1a2135;
    font-weight: 700;
    margin-bottom: 10px;
}

.product-body p {
    font-size: 16px;
    color: #1a2135;
    font-weight: 500;
    margin-bottom: 10px;
}

.product-btn {
    display: flex;
    align-items: center;
}

.product-btn a {
    font-size: 18px;
    color: #1a2135;
    font-weight: 700;
}

.product-btn i {
    color: #18f6c8;
    font-size: 30px;
    padding-left: 10px
}

.language-dropdown .dropdown-content {
    right: 0;
    top: 70px;
}

.contact-number {
    margin-bottom: 20px;
}

.contact-number li {
    display: inline-block;
    margin-right: 15px;
}

.contact-number li:last-child {
    margin-right: 0px;
}

.contact-number li a {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 10px;
    background-color: #fff;
    display: block;
}

.address-contact {
    font-size: 22px;
    font-weight: 600;
    color: #1a2135;
    margin-bottom: 10px;
}

.adrees-title {
    display: flex;
    align-items: center;
    gap: 15px;
}

.contact-info {
    padding-left: 15px !important;
}

.contact-info li a {
    display: block !important;
    margin-bottom: 5px !important;
}

.contact-info li a:hover {
    color: #fff;
}

.contact-info li a p {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.contact-info li a h3 {
    margin-bottom: 5px;
    color: #00f6d4;
    font-size: 18px;
    font-weight: 600;
}

.case-study {
    padding: 100px 0;
    background-color: #00f6d4;
}

.study-card {
    padding: 30px;
    background-color: #fff;
    position: relative;
    min-height: 350px;
    border-bottom: 3px solid #000;
}

.slider .slick-slide {
    padding: 20px;
}

.study-title {
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
}

.study-title h4 {
    font-size: 22px;
    font-weight: 700;
    color: #00f6d4;
    background-color: #000;
    padding: 10px 15px;
    text-align: center;
    border-radius: 5px;
}

.case-study {
    padding: 70px 0 100px;
    min-height: 400px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../assets/images/banner-04.jpg');
    background-position:center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
}
.study-img img{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 35%;
    object-fit: cover;
}
.study-box{
    padding:0 30px;
}
.study-content {
    padding:  30px 20px 20px;
    min-height: 300px;
    background-color: #fff;
}

.study-content h5 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}

.study-content p {
    font-size: 17px;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
}

.slider-content .slick-arrow {
    display: none !important;
}

.slider-content .slick-dots {
    bottom: -50px;
}

.slider-content .slick-dots li.slick-active button:before {
    background-color: #00f9cb;
}

.slider-content .slick-dots li button:before {
    color: #fff;
    opacity: 1;
}

.slider-content .slick-slider {
    min-height: 350px;
}

.slider-content .slick-dots li button{
    width: 40px;
    height: 2px;

}
.slider-content .slick-dots li{
    width: 30px;
    height: 3px;
}
.slider-content .slick-dots li button:before{
    content:"" !important;
    width: 30px;
    height:3px;
    background-color: #fff;
}
.slider-content .slick-dots li button:hover:before, .slider-content .slick-dots li button:focus:before, .slider-content .slick-dots li button:active:before {
    background-color: #00f9cb;
}

.service-datalist .list-img img {
    width: 30px;
}

.partner-back .slick-slider .slick-slide {
    padding: 0 15px;
  }

  .partner-back .slick-prev, .partner-back .slick-next{display: none !important;}
  span.navbar-brand img {
    width: 70%;
}
.product-body-img .body-left img {
    width: 56%;
}
@media(max-width:1199px) {
    .top-icon {
        width: 23%;
    }

    .banner-text h1 {
        font-size: 60px;
    }

    .scroll-btn {
        height: 145px;
        max-width: 100%;
    }

    .slider-content .slick-arrow.slick-prev {
        right: 240px;
    }

    .slider-content .slick-arrow.slick-next {
        right: 190px;
    }

    .banner-text p {
        font-size: 16px;
    }

    .card-body {
        padding: 30px;
    }

    .card-body p.img-title {
        margin-bottom: 0;
    }

    .list-text {
        width: 75%;
    }

    li.nav-item {
        margin: 0 5px;
    }

    .body-img {
        margin-bottom: 20px;
    }

    .shape-one {
        width: 195px;
        top: -18px;
    }

    .product-body {
        padding: 30px 20px;
    }

}


@media(max-width:1199px) {
    .top-icon {
        width: 23%;
    }

    .banner-text h1 {
        font-size: 60px;
    }

    .scroll-down {
        height: 145px;
        max-width: 100%;
    }

    .slider-content .slick-arrow.slick-prev {
        right: 240px;
    }

    .slider-content .slick-arrow.slick-next {
        right: 190px;
    }

    .banner-text p {
        font-size: 16px;
    }

    .card-body {
        padding: 30px 20px;
        min-height: 320px !important;
    }

    .card-body p.img-title {
        margin-bottom: 0;
    }

    .list-text {
        width: 75%;
    }

    li.nav-item {
        margin: 0 5px;
    }

    .body-img {
        margin-bottom: 20px;
        width: 55px;
    }

    .card-img {
        height: 200px;
    }

    .shape-one {
        width: 195px;
        top: -18px;
    }

    .product-body {
        padding: 30px 20px;
    }

    .product-body-img {
        padding-bottom: 15px;
    }

    .product-body h5 {
        margin-bottom: 10px;
    }

    .product-body p {
        margin-bottom: 0;
    }

    .study-box {
        padding: 0 15px;
    }

    .study-title h4 {
        font-size: 20px;
    }

    .study-img img {
        object-position: 80% 0;
        width: 32%;
    }

    .scroll-btn {
        bottom: -35px;
    }
}

@media(max-width:991px) {
    .go-down-btn {
        top: 40px;
    }

    .scroll-btn {
        max-width: 100%;
        bottom: -30px;
    }

    .study-img img {
        object-position: 68% 0;
        width: 30%;
    }

    .language-dropdown {
        margin-top: 20px;
    }

    .top-info {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px 0;
    }

    .top-menu::after {
        display: none;
    }

    .top-icon {
        width: 100%;
    }

    .top-icon ul {
        justify-content: center;
    }

    .banner-text h1 {
        font-size: 55px;
    }

    .banner-text p {
        font-size: 16px;
    }

    .banner-text {
        width: 75%;
    }

    .about {
        padding-bottom: 60px;
    }

    .shape-one {
        width: 190px;
        top: -13px;
    }

    .scroll-down {
        height: 150px;
    }

    .about-left {
        padding-bottom: 30px;
    }

    .project-count h4 {
        font-size: 22px;
    }

    .project-count {
        height: 150px;
    }

    .project-count h3 {
        font-size: 38px;
    }

    .img-card {
        padding: 60px 0;
    }

    .card-img img {
        object-position: 10%;
    }

    .card-body {
        padding: 30px 15px;
        min-height: 250px !important;
    }

    h5.img-text {
        line-height: normal;
        font-size: 22px;
    }

    p.img-title {
        font-size: 15px;
    }

    .why-choose {
        padding: 60px 0;
    }

    .service-data {
        padding-bottom: 30px;
    }

    .slider-content .slick-arrow.slick-next {
        right: 150px;
    }

    .slider-content .slick-arrow.slick-prev {
        right: 200px;
    }

    .slider {
        padding: 60px 0;
    }

    .partner {
        padding: 60px 0;
    }

    .dummy-img {
        padding: 10px;
    }

    .footer-info {
        padding-bottom: 30px;
    }

    .footer-bottom {
        padding: 30px 0 !important;
    }

    .navbar-toggler {
        padding: 8px 10px;
    }

    .address .address-card {
        margin-bottom: 30px;
    }

    .address {
        padding: 60px 0;
    }

    .form-text {
        max-width: 75%;
    }

    .form-btn a {
        font-size: 16px;
    }

    .right-text h5 {
        font-size: 26px;
    }

    .right-text p {
        font-size: 16px;
    }

    .language-dropdown .dropdown-content {
        left: 0;
        top: 60px;
    }

    .offcanvas.offcanvas-end {
        max-width: 300px;
    }

    .navbar-nav {
        align-items: flex-start;
    }
}

@media(max-width:767px) {
    span.navbar-brand {
        width: 80%;
    }
    .scroll-btn {
        bottom: -15px;
    }

    .study-img img {
        display: none;
    }

    .footer-info p {
        width: 100%;
    }

    .slider-content .slick-arrow.slick-next {
        right: 120px;
    }

    .slider-content .slick-arrow.slick-prev {
        right: 170px;
    }

    .footer-bottom {
        padding: 10px 0 !important;
    }

    .top-text ul {
        flex-direction: column;
    }

    .banner-text h1 {
        font-size: 40px;
    }

    .scroll-btn {
        height: 120px;
    }

    .shape-one {
        width: 150px;
        top: -12px;
    }

    .scroll-icon {
        top: 25px;
        width: 75px;
        height: 75px;
    }

    .about-counter {
        padding-top: 30px;
    }

    .project-count {
        margin-bottom: 30px;
    }

    .card-info {
        margin-bottom: 30px;
        height: auto;
    }

    .form-text {
        width: 100%;
    }

    .form-data {
        margin-bottom: 30px;
        height: auto;
    }

    .form-text h4 {
        font-size: 30px;
    }

    .dummy-img {
        border-bottom: 1px solid #000;
    }

    .card-body {
        min-height: 290px !important;
    }

    .go-down-btn {
        top: 20px;
    }
}

@media(max-width:575px) {
    .card-body {
        min-height: auto !important;
    }

    .go-down-btn {
        top: 20px;
    }

    .top-text ul li a {
        font-size: 13px;
        padding-left: 0;
    }

    .top-text ul li a i {
        margin-right: 6px;
        font-size: 15px;
    }

    .slider-text {
        margin-bottom: 30px;
    }

    .banner-text {
        width: 95%;
    }

    .banner-text h1 {
        font-size: 30px;
    }

    .scroll-btn {
        height: 85px;
    }

    .card-info {
        max-width: 280px;
        margin: auto auto 30px;
    }

    .list-img {
        width: 60px;
        height: 60px;
    }

    .scroll-icon {
        top: 37px;
    }

    .shape-one {
        top: 0;
    }

    .service-img::after {
        width: 280px;
        left: 0;
        margin: auto;
    }

    .service-img img {
        width: 280px;
    }

    .service-img-postion {
        display: none;
    }

    .service-img {
        height: auto;
    }

    .service-img {
        text-align: center !important;
    }

    .card-body {
        min-height: auto;
    }

    .service-datalist ul li {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .list-text {
        margin-left: 0;
        width: 100%;
    }
}

@media(max-width:480px) {
    .slider-content .slick-arrow.slick-prev {
        right: 0;
        left: -50px;
        margin: auto;
        top: auto;
        bottom: -65px;
    }

    .slider-content .slick-arrow.slick-next {
        right: -50px;
        left: 0;
        margin: auto;
        top: auto;
        bottom: -65px;
    }
}

@media (max-width: 400px) {
    span.navbar-brand {
        width: 70%;
    }
}